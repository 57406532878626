<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout column fill-height overflow-hidden>
		<v-flex
			v-if="isAccountant && members.filter(member => member.id === currentUserId).some(member => member.role_id.role_id === 1)"
			v-show="!minimized"
			shrink
			my-3
			px-3
			justify-center
		>
			<v-checkbox v-model="filters.adminMode" class="ma-0 pa-0" color="primary" hide-details :label="$t('client_workspace.task.see_every_company')" />
		</v-flex>
		<v-flex v-show="!minimized" shrink my-3 px-3 justify-center>
			<v-checkbox v-model="filters.internalTasks" class="ma-0 pa-0" color="primary" hide-details :label="$t('client_workspace.tasks.filters.internal')" />
		</v-flex>
		<v-flex v-show="!minimized" shrink my-3 px-3 justify-center>
			<v-checkbox
				v-model="selectAll"
				class="ma-0 pa-0"
				color="primary"
				:indeterminate="someSelected"
				hide-details
				:label="$t('client_workspace.task.every_company')"
			/>
		</v-flex>
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex grow overflow-hidden style="height: 0">
			<v-layout column fill-height>
				<v-flex v-show="!minimized" shrink px-3>
					<v-text-field
						v-model="companyTextSearch"
						class="pt-0 mb-2"
						hide-details
						single-line
						clearable
						:label="$t('actions.search_company')"
						prepend-inner-icon="search"
					/>
				</v-flex>
				<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
				<v-flex grow scroll-y overflow-hidden style="height: 0">
					<v-list v-if="loading" class="pt-0">
						<v-list-tile full-width>
							<v-list-tile-content>
								<v-progress-circular class="mx-2" indeterminate></v-progress-circular>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>
					<w-virtual-list v-else class="pt-0" :items="filteredCompanyList" :item-size="56">
						<template v-slot:default="{ item: company }">
							<v-list-tile full-width class="pointer">
								<v-flex v-show="!minimized" shrink justify-center>
									<v-checkbox v-model="company.isFiltered" class="ma-0 pa-0" color="primary" hide-details label=""></v-checkbox>
								</v-flex>
								<v-list-tile-avatar @click.prevent="company.isFiltered = !company.isFiltered">
									<v-avatar size="2.5em" color="primary">
										<v-flex white--text>{{ company.abbreviation }}</v-flex>
									</v-avatar>
								</v-list-tile-avatar>
								<v-list-tile-content @click.prevent="company.isFiltered = !company.isFiltered">
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-list-tile-title :class="company.isFiltered ? 'primary--text text-truncate' : 'text-truncate'" v-on="on" v-html="company.nameHtml" />
										</template>
										<span>{{ company.fullname }}</span>
									</v-tooltip>
								</v-list-tile-content>
							</v-list-tile>
						</template>
					</w-virtual-list>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'
export default {
	name: 'TasksManagerFilterCustomer',
	mixins: [TasksManagerModuleGuard],
	props: {
		minimized: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: function () {
		return {
			loading: false,
			companyTextSearch: '',
			selectAll: true,
			companyList: [],
			filteredCompanyList: []
		}
	},
	computed: {
		...mapState({
			currentUserId: state => state.user.id,
			companies: state => state.tasks.companies,
			isAccountant: state => state.auth.isAccountant,
			filters: state => state.tasks.filters,
			adminMode: state => state.tasks.filters.adminMode,
			members: state => state.tasks.members,
			accountingFirm: state => state.accountingFirm.selected
		}),
		someSelected: function () {
			return this.companyList.some(company => company.isFiltered) && !this.companyList.every(company => company.isFiltered)
		}
	},
	watch: {
		minimized: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		filters: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		sidebar: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		adminMode: {
			immediate: true,
			handler: function (newValue) {
				this.loading = true
				this.service.loadCompanies(true, newValue).finally((this.loading = false))
			}
		},
		companies: {
			immediate: true,
			deep: true,
			handler: function () {
				this.computeFilteredCompanyList()
			}
		},
		companyTextSearch: {
			immediate: false,
			deep: false,
			handler: function () {
				this.computeFilteredCompanyList()
			}
		},
		companyList: {
			immediate: true,
			deep: true,
			handler: function () {
				if (this.filters.companies) {
					this.filters.companies = [...this.companyList.filter(company => company.isFiltered).map(company => company.id)]
				}
			}
		},
		selectAll: {
			immediate: true,
			deep: true,
			handler: function (newValue) {
				const selected = newValue !== null ? newValue : false
				this.companyList.forEach(company => (company.isFiltered = selected))
			}
		},
		accountingFirm: {
			immediate: true,
			handler: function () {
				this.loading = true
				this.service.loadCompanies(true).finally((this.loading = false))
			}
		}
	},
	methods: {
		computeFilteredCompanyList: function () {
			this.insureOfCompanyListCompleteness()
			this.filteredCompanyList.clear()
			this.filteredCompanyList = [
				...this.companyList
					.filter(company => !this.companyTextSearch || company.fullname.toLowerCase().indexOf(this.companyTextSearch.trim().toLowerCase()) !== -1)
					.map(company => {
						company.nameHtml = this.$highlightMatching(company.fullname, this.companyTextSearch)
						return company
					})
			]
		},
		insureOfCompanyListCompleteness: function () {
			let results = []
			this.companies.forEach(company => {
				let companyEntry
				if (this.companyList.some(item => item.id == company.id)) {
					companyEntry = this.companyList.find(item => item.id == company.id)
				} else {
					companyEntry = Object.assign({}, company)
					companyEntry.isFiltered = this.selectAll
				}
				results.push(companyEntry)
			})
			this.companyList = [...results]
		}
	}
}
</script>
